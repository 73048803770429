<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="headers"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Seções de Texto</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-card-actions>
          <v-btn
            color="primary"
            dark class="mb-2"
            @click="exportData()"
            :loading="task_in_progress"
          >
            Download Minuta
          </v-btn>
        </v-card-actions>

        <v-dialog v-model="dialog" max-width="100%">
          <v-card>
            <v-card-title>
              <span class="headline"></span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-text-field
                        v-model="Item.titulo"
                        label="Título"
                        :error-messages="errors['titulo']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-col>
                        <editor v-model="Item.texto" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
              <v-btn :loading="saving" color="darken-1" text @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Task, GenerateMinuta } from "@/api/core";
import { Secao } from "@/api/eqpdti";
import Editor from "@/components/Editor";
export default {
  name: "Secao",
  components: {
    Editor,
  },
  data: () => ({
    content: "",
    data: [],
    headers: [
      {
        text: "Título",
        value: "titulo",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {},
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
    task_in_progress: false,
  }),
  watch: {
    content: console.log,
  },
  methods: {
    clearContent() {
      this.editor.clearContent(true);
      this.editor.focus();
    },
    setContent() {
      // you can pass a json document
      this.editor.setContent(
        {
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "This is some inserted text. 👋",
                },
              ],
            },
          ],
        },
        true
      );

      this.editor.focus();
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.saving = true;
      let _Item = this.Item;
      _Item.texto = JSON.stringify(_Item.texto);
      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (_Item["id"]) {
        let id = _Item["id"];

        Secao.update(id, _Item)
          .then(_then)
          .catch(_catch);
      } else {
        Secao.post(_Item)
          .then(_then)
          .catch(_catch);
      }
    },
    edit(item) {
      let texto = "";
      try {
        texto = JSON.parse(item.texto);
      } catch {
        texto = item.texto;
      } finally {
        item.texto = texto;
      }
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      this.tableLoading = true;
      Secao.remove(item["id"]).then(() => {
        this.tableLoading = false;
        this.fetch();
      });
    },
    fetch() {
      this.tableLoading = true;
      Secao.list().then((response) => {
        this.data = response.data;
        this.tableLoading = false;
      });
    },
    getColor(gut) {
      if (gut > 85) return "red";
      else if (gut > 45) return "orange";
      else return "green";
    },
    monitor(task_id) {
      Task.get(task_id)
        .then((response) => {
          this.task_in_progress = true;
          this.tableLoading = true;
          if (response.status == 202) {
            setTimeout(() => this.monitor(task_id), 2000);
          } else {
            this.task_in_progress = false;
            this.tableLoading = false;
            window.location.replace(
              `${process.env.VUE_APP_API_URL}/api/core/download/${response.data.file_id}/`
            );
          }
        })
        .catch((error) => {
          this.task_in_progress = false;
          this.tableLoading = false;
          console.log(error);
        });
    },
    exportData() {
      if (window.confirm("Deseja realmente realizar o download? Está ação pode demorar alguns minutos.")) {
        GenerateMinuta.post()
          .then((response) => {
            this.task_in_progress = true;
            this.tableLoading = true;
            this.monitor(response.data.task_id);
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

